<template>
  <div class="view pa24">
    <el-card class="box-card" shadow="never">
      <div slot="header" class="clearfix">
        <h3>1.佣金入账周期设置</h3>
      </div>
      <div>
        订单状态变为“已完成”后
        <el-input style="width: 70px" v-model="settlementDate" />
        天
      </div>
    </el-card>
    <el-card class="box-card mt20" shadow="never">
      <div slot="header" class="clearfix">
        <h3>2.打款方式</h3>
      </div>
      <div class="mb10">
        自动打款
        <el-switch v-model="switchValue"> </el-switch>
      </div>
      <span style="color: #7f7f7f"
        >提示：开启自动打款后，分销员佣金提现后将无需审核，佣金自动到账至分销者账户</span
      >
    </el-card>
    <el-card class="box-card mt20" shadow="never">
      <div slot="header" class="clearfix">
        <h3>3.佣金提现额度设置</h3>
      </div>
      <div class="mb10">
        佣金提现金额满
        <el-input-number style="width: 100px"
                         v-model="num" 
                         controls-position="right"  
                         min="0.3"
                         ></el-input-number>
        元，才可提现
      </div>
      <span style="color: #7f7f7f"
        >提示：微信提现要求至少0.3元</span
      >
    </el-card>
    <div class="mt20" style="text-align:center">
      <el-button  type="primary" plain @click="saveSetting">保存</el-button>
      <p class="mt10">提示：保存后即时生效，分销佣金都按照本设置入账</p>
    </div>
  </div>
</template>

<script>
import { updateSetting ,querySetting} from "@/api/financing";
export default {
  name: "commissionSettings",
  data() {
    return {
      switchValue: false,
      num:1,
      settlementDate:15
    };
  },
  created(){
    this.getSetting()
  },
  methods:{
    getSetting(){
        let data =  {
          xx: 'x',
        }
      querySetting(data)
      .then(res => {
        if(res.data){
          this.switchValue = res.data.autoArrive;
          this.settlementDate = res.data.settlementDate;
          this.num = res.data.cashAmount;
        }
      }).catch(function (err) {
      });
    },
    saveSetting(){
      let data = {
        autoArrive : this.switchValue,
        settlementDate : this.settlementDate,
        cashAmount: this.num,
      }
      updateSetting(data)
      .then(res=>{
        if(res.code == 200){
        this.$message({
          message: "保存成功",
          type: "success",
        });
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
</style>